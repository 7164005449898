import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Customer, User, PhoneNumber } from '@londonhydro/utility-model';
import { AbstractComponent, AppConfg, APP_CONIG, UIUtil, BrandingConfig, BRANDING_CONFIG } from '@londonhydro/ux-lib';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

import { CsrDataService } from '../../backend/csr/dataservice/csr-data.service';
import { BlockAccountFormComponent } from '../block-account-form/block-account-form.component';
import { ChangeLoginEmailFormComponent } from '../change-login-email-form/change-login-email-form.component';
import { CloseOnlineAccountFormComponent } from '../close-online-account-form/close-online-account-form.component';
import { ResendInvitationComponent } from '../resend-invitation/resend-invitation.component';
import { SendRegistraionEmailFormComponent } from '../send-registraion-email-form/send-registraion-email-form.component';
import { UnblockAccountFormComponent } from '../unblock-account-form/unblock-account-form.component';
import { LinkCustomerComponent } from '../link-customer/link-customer.component';
import { UnlinkCustomerComponent } from '../unlink-customer/unlink-customer.component';

@Component({
  selector: 'app-customer-lookup-form',
  templateUrl: './customer-lookup-form.component.html',
  styleUrls: ['./customer-lookup-form.component.scss']
})
export class CustomerLookupFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['customerId', 'name', 'email', 'unlink'];

  searchForm: FormGroup;

  brandingName;
  customerInfo: Customer;
  customerList: Customer[];
  userAccount: any;
  userList: any[];

  isError: boolean;
  showPageLoader: boolean;
  showContent: boolean;

  notYetRegistered: boolean;
  isShellAccount: boolean;
  isCSRrole: boolean;
  isDelegateUser: boolean;
  registrationPending: boolean;

  private portalMap: any = [];
  portals: Array<any>;
  roles: any;
  pbAccountIds = [];
  myaccountUrl: string;
  uiTimezone: string;
  currentInputValue = null;
  valueIsDuplicate = false;
  state = 'table';
  linkCustomerEnabled = false;
  disableActionForMulitpleInstances = false;
  isGreenButtonEnabled = false;
  isCustomerViewEnabled = true;
  isSendInvitationEnabled = true;

  phoneCategories = [
    {
      category: 'BUSINESS/WORK',
      label : 'BUSINESS/WORK'
    },
    {
      category: 'RESIDENTIAL',
      label : 'PRIMARY'
    },
    {
      category: 'NOTIFICATION',
      label : 'NOTIFICATION'
    },
    {
      category: 'CELL',
      label : 'CELL'
    }
  ];

  constructor(injector: Injector, private formBuilder: FormBuilder, private toastr: ToastrService,
    private csrDataService: CsrDataService, private matDialog: MatDialog,
    @Inject(BRANDING_CONFIG) public brandingConfig: BrandingConfig,
    @Inject(APP_CONIG) public appConfig: AppConfg) {
    super(injector);
    this.searchForm = this.formBuilder.group({
      searchKey: ['', [Validators.required]]
    }, { validator: this.fieldValidator });
    this.getBrandingList();
    this.getLinkCustomerConfig();
    this.getAppConfig();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  userSessionLoaded(): void {
    console.log('userSessionLoaded:::', this.user);
  }

  private reset(): void {
    this.roles = [];
    this.portals = [];
    this.portalMap = {};
    this.pbAccountIds = [];
    this.userAccount = null;
    this.userList = [];
    this.customerInfo = null;
    this.customerList = [];
    this.isDelegateUser = false;
    this.notYetRegistered = true;
    this.isShellAccount = false;
    this.showContent = false;
    this.isError = false;
    this.disableActionForMulitpleInstances = false;
  }

  fieldValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const searchInput = control.get('searchKey')?.value.trim();
    return searchInput.length < 1 ? { fieldInavlid: true } : null;
  }

  private showNoDataContent(show: boolean): void {
    this.isError = show;
    this.showContent = !show;
  }

  duplicateInputValidate(): boolean {
    return this.currentInputValue === this.searchForm.controls.searchKey.value.trim() ? true : false;
  }

  search(forceSearch = false, validateOnClick?: any, syncChild = false, childId?: string): void {
    this.valueIsDuplicate = false;
    if (validateOnClick) {          //check if value is same as current value
      this.valueIsDuplicate = this.duplicateInputValidate();
    }
    if (/* !this.valueIsDuplicate */true) {
      this.reset();
      const searchInput: string = this.searchForm.controls.searchKey.value.trim();
      this.currentInputValue = searchInput;
      if (syncChild && childId) {
        this.checkCustomerAvailability(childId, forceSearch, syncChild);
      } else{
        this.checkCustomerAvailability(searchInput, forceSearch);
      }
    }
  }

  checkCustomerAvailability(searchInput: any, forceSearch = false, syncChild = false): void {
    if (searchInput !== null || searchInput.trim().length > 0) {
      this.showPageLoader = true;
      this.csrDataService.getCustomerAvailability(searchInput.trim(), forceSearch, syncChild).subscribe((res) => {
        console.log('getCustomerAvailability::::', res);
        this.showPageLoader = false;
        this.searchWithInput(this.currentInputValue);
      }, (error: any) => {
        if (!syncChild) {
          this.toastr.clear();
          this.toastr.error('Something went wrong. Please try again.', 'Error', {
            timeOut: 5000
          });
        } else {
          this.searchWithInput(this.currentInputValue);
        }
        this.showPageLoader = false;
        if (syncChild){
          this.csrDataService.postError(searchInput.trim(), forceSearch, syncChild, 'cis_retry_limit_exceeded', 20);
        } else {
          this.csrDataService.postError(searchInput.trim(), forceSearch, syncChild, 'cis_retry_limit_exceeded', 20);
        }
      })
    }
  }

  searchWithInput(searchInput: any): void {
    if (searchInput !== null || searchInput.trim().length > 0) {
      this.showPageLoader = true;
      this.csrDataService.getCustomers(searchInput.trim()).subscribe((customerList: Customer[]) => {
        console.log('customerList::::', customerList);
        if (customerList && !_.isEmpty(customerList)) {
          this.customerList = customerList;
          if (this.customerList.length > 1) {
            this.disableActionForMulitpleInstances = true;
          }
          this.customerInfo = customerList[0];
          if (searchInput.includes('@')) {
            this.getUserSearch(encodeURIComponent(searchInput + ' + ' + this.customerInfo.id));
          } else {
            this.getUserDetailByCustomerId(encodeURIComponent(this.customerInfo.id));
          }
          this.showNoDataContent(false);
        } else {
          this.getUserDetailByUserName(searchInput.trim());
        }
      }, (error: any) => {
        this.toastr.error(error.error.message, 'error', {
          timeOut: 10000,
        });
        this.showNoDataContent(true);
        this.showPageLoader = false;
        console.error('getCSRSearchInfo error::::', error);
      });
    }
  }

  getUserDetailByCustomerId(customerId: string): void {
    this.csrDataService.getUserDetailByCustomerId(customerId).subscribe((userList: User[]) => {
      userList = _.sortBy(userList, function (user: User) {
        return user.customerId;
      }).reverse();
      console.log('userList::::', userList);
      if (userList && !_.isEmpty(userList)) {
        this.userList = userList;
        this.userAccount = userList[0];
        this.populateAdditionalData();
      } else {
        console.log('No user found for CustomerId:', customerId);
        this.getUnregisteredUserDetail(customerId);
      }
    }, (error: any) => {
      this.showPageLoader = false;
      console.error('getUserDetailByCustomerId error::::', error);
    });
  }

  getUnregisteredUserDetail(customerId: string): void {
    this.csrDataService.getUnregisteredUserDetail(customerId).subscribe((userData: User) => {
      console.log('userData::::', userData);
      if (userData) {
        this.userAccount = userData;
        this.populateAdditionalData();
      } else {
        this.showPageLoader = false;
        console.log('No user found for CustomerId:', customerId);
      }
    }, (error: any) => {
      this.showPageLoader = false;
      console.error('getUnregisteredUserDetail error::::', error);
    });
  }

  getUserDetailByUserName(userName: string): void {
    this.csrDataService.getUserDetailByUserName(userName).subscribe((userList: User[]) => {
      userList = _.sortBy(userList, function (user: User) {
        return user.customerId;
      }).reverse();
      console.log('userList::::', userList);
      if (userList && !_.isEmpty(userList)) {
        this.userList = userList;
        this.userAccount = userList[0];
        if (this.userAccount && this.userAccount.customerId) {
          this.csrDataService.getCustomerInfobyId(this.userAccount.customerId).subscribe((customerData: Customer) => {
            console.log('customerData::::', customerData);
            this.customerInfo = customerData || null;
            this.populateAdditionalData();
            this.showNoDataContent(false);
          });
        } else {
          this.populateAdditionalData();
          this.showNoDataContent(false);
        }
      } else {
        this.showNoDataContent(true);
        this.showPageLoader = false;
      }
    }, (error: any) => {
      this.showNoDataContent(true);
      console.error('getUserDetailByUserName error::::', error);
      this.showPageLoader = false;
    });
  }

  getUserDetails(userName: string, customerId: string): void {
    this.csrDataService.getUserDetails(userName, customerId).subscribe((userList: User[]) => {
      userList = _.sortBy(userList, function (user: User) {
        return user.customerId;
      }).reverse();
      console.log('userList::::', userList);
      if (userList && !_.isEmpty(userList)) {
        this.userList = userList;
        this.userAccount = userList[0];
        this.populateAdditionalData();
      } else {
        console.log('No user found for CustomerId:', customerId);
        this.getUnregisteredUserDetail(customerId);
      }
    }, (error: any) => {
      this.showPageLoader = false;
      console.error('getUserDetailByCustomerId error::::', error);
    });
  }

  getUserSearch(searchText: string): void {
    this.csrDataService.getUserSearch(searchText).subscribe((userList: User[]) => {
      userList = _.sortBy(userList, function (user: User) {
        return user.customerId;
      }).reverse();
      console.log('userList::::', userList);
      if (userList && !_.isEmpty(userList)) {
        this.userList = userList;
        this.userAccount = userList[0];
        this.populateAdditionalData();
      } else {
        console.log('No user found for CustomerId:', this.customerInfo.id);
        this.getUnregisteredUserDetail(this.customerInfo.id);
      }
    }, (error: any) => {
      this.showPageLoader = false;
      console.error('getUserDetailByCustomerId error::::', error);
    });
  }

  populateAdditionalData(): void {
    if (this.userAccount) {
      this.notYetRegistered = (this.userAccount.username && (this.userAccount.username.indexOf('(Not Registered Yet)') >= 0
        || this.userAccount.username.indexOf('NotRegister') >= 0)) ? true : false;
      console.log('notYetRegistered', this.notYetRegistered);
      this.isCSRrole = _.filter(this.userAccount.authorities, (res: any) => {
        return res.roleCode.toLowerCase().includes('csr') }).length > 0;
      this.isShellAccount = (this.userAccount.customerId != null || this.userAccount.customerID != null) ? false : true;
      console.log('isShellAccount', this.isShellAccount);
      this.registrationPending = (this.userAccount.userStatus === 'Pending' || this.userAccount.userStatus === 'Expired') ? true : false;
      console.log('RegistrationPending', this.registrationPending);

      const userRoles = this.userAccount.authorities || this.userAccount.roles;
      if (userRoles && userRoles.length === 1) {
        if (userRoles[0].roleCode === 'User' || userRoles[0].roleName === 'User') {
          this.updatePortalMap('MyAccount Portal', 'Shell Account');
        }
      }

      _.each(userRoles, (val) => {
        const roleCodeName = val.roleCode || val.roleName;
        this.roles.push(roleCodeName);
        if (roleCodeName === 'Owner' || roleCodeName === 'OWNER' || roleCodeName === 'Billing' || roleCodeName === 'Usage' ||
          roleCodeName === 'CSR' || roleCodeName === 'ENERGYCSR' || roleCodeName === 'WATERCSR' || roleCodeName === 'DELEGATE') {
          if (roleCodeName === 'WATERCSR') {
            this.updatePortalMap('MyAccount Portal', 'Water Restricted CSR');
          } else if (roleCodeName === 'ENERGYCSR') {
            this.updatePortalMap('MyAccount Portal', 'Energy Restricted CSR');
          } else {
            this.updatePortalMap('MyAccount Portal', roleCodeName);
          }
        } else if (roleCodeName === 'PropertyOwner' || roleCodeName === 'PropertyDelegate' || roleCodeName === 'PropertyManager') {
          this.updatePortalMap('Property Management Portal', roleCodeName);
          // this.roles.push(roleCodeName);
        } else if (roleCodeName === 'Builder' || roleCodeName === 'SiteContract') {
          this.updatePortalMap('Builder\'s Portal', roleCodeName);
          // this.roles.push(roleCodeName);
        } else if (roleCodeName === 'IDC' || roleCodeName === 'Employee') {
          this.updatePortalMap('Interval Data Center', roleCodeName);
          // this.roles.push(roleCodeName);
        }
      });
      this.portals = _.uniq(this.portals);
      this.roles = _.filter(this.roles, (val) => val !== 'User');
    }
    if (this.userAccount && this.userAccount.createdDate) {
      this.userAccount.createdDate = moment.utc(this.userAccount.createdDate).tz(UIUtil.UiTimeZone);
    }
    if (this.userAccount && this.userAccount.loginTryDate) {
      this.userAccount.loginTryDate = moment.utc(this.userAccount.loginTryDate).tz(UIUtil.UiTimeZone);
    }
    if (this.userAccount && this.userAccount.status && this.userAccount.status.date) {
      this.userAccount.status.date = moment.utc(this.userAccount.status.date).tz(UIUtil.UiTimeZone);
    }
    this.showPageLoader = false;
    console.log('this.customerInfo:::', this.customerInfo);
    console.log('this.userAccount:::', this.userAccount);
    console.log('this.roles', this.roles);
  }

  updatePortalMap(portalName: string, roleCode: string): void {
    let prole = this.portalMap[portalName];
    if (!prole || prole === undefined) {
      prole = '';
    } else {
      prole = (prole === 'Billing') ? (prole + '-Delegate , ') : (prole + ', ');
    }

    prole = prole + roleCode;
    this.portalMap[portalName] = prole;
    console.log('this.portalMap[portalName]', this.portalMap[portalName]);
    this.portals.push(portalName);
    console.log('this.portals', this.portals);
  }

  getLinkCustomerConfig(){
    if (this.appConfig && this.appConfig['linkCustomer'] && this.appConfig['linkCustomer']['enable']){
      this.linkCustomerEnabled = this.appConfig['linkCustomer']['enable'];
    }
  }

  getAppConfig(): void {
    if (this.appConfig?.greenButtonUrl) {
      this.isGreenButtonEnabled = true;
    }
    if (this.appConfig?.customerView?.enabled === false) {
      this.isCustomerViewEnabled = false;
    }
    if (this.appConfig?.sendInvitation?.enabled === false) {
      this.isSendInvitationEnabled = false;
    }
  }

  getBrandingList(): void {
    this.csrDataService.getBrandingList().subscribe((response: any) => {
      if(response){
      const key = Object.keys(response);
      const brand = key[0];
      if(response[brand]){
        this.brandingName = response[brand]['customerPortalName'];
      }
      console.log('brandingName::', this.brandingName);
      }
    })

  }

  openCustomerView(): void {
    console.log('this.userAccount:::::', this.userAccount);
    const customerId = this.customerInfo.id;
    let username = '';
    let userId = '';
    if (this.userAccount) {
      username = this.userAccount.username;
      userId = this.userAccount.id;
    }
    const v = (new Date()).getTime();
    const myAccountViewUrl = this.appConfig['myAccountUrl'] + `?v=${v}&customerId=${customerId}` + (this.userAccount ? (`&username=${encodeURIComponent(username)}&userId=${userId}`) : '');
    console.log('myAccountViewUrl:::::', myAccountViewUrl);
    window.open(myAccountViewUrl, '_view' + customerId);
  }

  openGreenButtonView(): void {
    const customerId = this.customerInfo?.id;
    const greenButtonViewUrl = this.appConfig.greenButtonUrl + `?customerId=${customerId}`;
    window.open(greenButtonViewUrl, '_view' + customerId);
  }

  clear(ctrl: FormGroup): void {
    ctrl.setValue(null);
  }

  showChangeLogin(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = data;
    const emailChangeViewer = this.matDialog.open(ChangeLoginEmailFormComponent, dialogConfig);
  }

  showBlockAccount(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data, siteName: this.brandingName };
    const blockAccountViewer = this.matDialog.open(BlockAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      console.log('after closed::', res);
      if (res && res.toString() === 'success') {
        this.search();
      }
    });
  }

  showCloseOnlineAccount(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = data;
    const blockAccountViewer = this.matDialog.open(CloseOnlineAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      console.log('after closed::', res);
      if (res && res.toString() === 'success') {
        this.search();
      }
    });
  }

  sendRegistrationEmail(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'send-email-modal-component' + data.id;
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = data;
    const showSendRegistrationEmail = this.matDialog.open(SendRegistraionEmailFormComponent, dialogConfig);
  }

  sendInvitationEmail(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'send-invitation-email-modal-component';
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      customerId: this.customerInfo.id,
      emailAddress: this.customerInfo.emails[0].emailAddress,
      firstName: this.customerInfo.firstName,
      lastName: this.customerInfo.lastName,
      userRole: 'OWNER'
    };
    const showSendInvitationEmail = this.matDialog.open(ResendInvitationComponent, dialogConfig);
  }

  showUnblockAccount(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'unblock-account-modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data, siteName: this.brandingName };
    const unblockAccountViewer = this.matDialog.open(UnblockAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      console.log('after closed::', res);
      if (res && res.toString() === 'success') {
        this.search();
      }
    });
  }

  linkCustomer(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'unblock-account-modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data, siteName: this.brandingName };
    const linkCustomerViewer = this.matDialog.open(LinkCustomerComponent, dialogConfig).afterClosed().subscribe((res) => {
      console.log('after closed::', res);
      if (res && res.msg && res.msg.toString() === 'success') {
        if (res.childId){
          this.search(true, false, true, res.childId);
        } else {
          this.search(true, false, true);
        }
      }
    });
  }

  unlinkCustomer(data: any, parentData : any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'unblock-account-modal-component' + data.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = { data, siteName: this.brandingName, parentData : parentData };
    const unlinkCustomerViewer = this.matDialog.open(UnlinkCustomerComponent, dialogConfig).afterClosed().subscribe((res) => {
      console.log('after closed::', res);
      if (res && res.toString() === 'success') {
        this.search();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  spaceReplaced(text: string): string {
    return text ? text.replace(/([\s]*,[\s]*)/gi, ', ') : '';
  }

  hasPhoneCategory(key: string): boolean {
    if (!this.customerInfo || (this.customerInfo && !this.customerInfo.phoneNumbers)) {
      return false;
    }
    if (this.customerInfo.phoneNumbers instanceof Map) {
      return this.customerInfo.phoneNumbers.has(key);
    }
    else {
      return this.customerInfo.phoneNumbers[key];
    }
  }

  getPhoneNumbers(key: string): any[] {
    if (!this.customerInfo || (this.customerInfo && !this.customerInfo.phoneNumbers)) {
      return [];
    }
    if (this.customerInfo.phoneNumbers instanceof Map) {
      return this.customerInfo.phoneNumbers.get(key).length ? this.customerInfo.phoneNumbers.get(key) : [];
    }
    else {
      return (this.customerInfo.phoneNumbers[key] as any[]).length ? this.customerInfo.phoneNumbers[key] : [];
    }
  }

  formatPhnNumber(phnNumber: string): string {
    if(!phnNumber) {
      return '-';
    }
    if(phnNumber.length > 10) {
      return phnNumber;
    }
    else if (phnNumber.length === 10) {
      return phnNumber.substring(0,3) + '-' + phnNumber.substring(3,6) + '-' + phnNumber.substring(6);
    }
    else {
      return '-';
    }
  }

  onToggle(type: string) {
    this.state = type ==='table' ? 'list' : 'table' ;
  }
}
