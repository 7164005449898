import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IamDataService, IamUserTxnService } from '@londonhydro/iam-api';
import { UIUtil } from '@londonhydro/ux-lib';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';

const nestedProperty = (data: any, sortHeaderId: string): string | number => {
  return sortHeaderId
    .split('.')
    .reduce((accumulator, key) => accumulator && accumulator[key], data) as | string | number;
};

const sortingDataAccessor = {
  nestedProperty,
};

@Component({
  selector: 'app-pending-registration',
  templateUrl: './pending-registration.component.html',
  styleUrls: ['./pending-registration.component.scss']
})
export class PendingRegistrationComponent implements OnInit, AfterViewInit {


  showPageLoader = false;
  @ViewChild('formsPaginator') formsPaginator: MatPaginator;
  allForms = null;
  formListDS: MatTableDataSource<any> = null;
  displayedColumns: string[];
  concatUsernameInfo = '';
  currentDate = new Date();
  refreshPendingRegApi=false;
  @ViewChild('serviceReqTableSort') sort: MatSort;

  formAttributeConfig: any[] = [
    { name: 'username', label: 'Login Email', type: 'string', class: 'pendingRegUsername' },
    { name: 'createDate', label: 'Registration Date', type: 'createDate', class: 'pendingRegCreateDate' },
    { name: 'linkExpired', label: 'Link Expired (Y/N)', type: 'linkExpiredDate', class: 'linkExpired'},
    { name: 'pendingSince', label: 'Days Pending', type: 'string', class: 'pendingSince' },
    { name: 'notes', label: 'Notes', type: 'notes', class: 'notes' }
  ];

  constructor(private toastr: ToastrService, private cd: ChangeDetectorRef, private formBuilder: FormBuilder,
    private iamUserTxnService: IamUserTxnService, private iamDataService: IamDataService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.formListDS = new MatTableDataSource<any>([]);
    this.formListDS.sortingDataAccessor = sortingDataAccessor.nestedProperty;
    this.displayedColumns = _.union(['index'], _.pluck(this.formAttributeConfig, 'name'));
    this.getCustomerPendingRegistraions();
  }

  ngAfterViewInit(): void {
    this.formListDS.sort = this.sort;
  }

  refreshApi():void{
    this.refreshPendingRegApi=true;
    this.getCustomerPendingRegistraions();
  }

  getCustomerPendingRegistraions(): void {
   
    this.iamDataService.getPendingRegistraions();
    this.iamDataService.getPendingRegistraion.subscribe(
      (res: any) => {
        if (res) {
          _.each(res, (element, index) => {
            element['rowIndex'] = (index + 1);
            element['isSelected'] = false;
            element['pendingSince'] = Math.round((new Date(new Date().getTime() - new Date(element.status.date).getTime())
              .getTime()) / (1000 * 60 * 60 * 24));
            element['createDate'] = moment.utc(element.status.date).tz(UIUtil.UiTimeZone).toDate();
            element['linkExpired'] = moment.utc(element.expiryDate).tz(UIUtil.UiTimeZone).toDate();
            element['roles']='';
            _.each( element.authorities,(data,index)=>{
              element['roles']=element['roles']+data.roleCode;
            });
          });
          this.allForms = res.sort(
            (objA, objB) => objB.createDate - objA.createDate,
          );
          console.log('Get Pending Reg Resp::', this.allForms);
          this.formListDS.data = this.allForms;
          setTimeout(() => this.formListDS.paginator = this.formsPaginator);
          this.showPageLoader = false;
          this.cd.detectChanges();
        }
        this.showPageLoader = false;
      },
      (error: any) => {
        console.log('error getCustomerPendingRegistraions)', error);
        this.showPageLoader = false;
      }
    );
     if(this.refreshPendingRegApi){
      this.showPageLoader=true;
      this.refreshPendingRegApi=false;
    }
  }


  deletePendingRegistraions(): void {
    const params = JSON.parse(JSON.stringify({
      remark: 'pending-registration-remove'
    }));

    let idStr: string[];
    idStr = this.concatUsernameInfo.split(';');
    idStr = idStr.slice(0, idStr.length - 1);
    let counter = 0;
    for (const i in idStr) {

      if (idStr[i]) {
        this.iamUserTxnService.closeOnlineAccountFromIAM(idStr[i], params).subscribe(
          (res: any) => {
            console.log('success deletePendingRegistraions', res);
            counter++;
            if (counter === idStr.length) {
              this.concatUsernameInfo = '';
              this.getCustomerPendingRegistraions();
            }
            this.toastr.success('Selected pending registrations deleted', 'Success', {
              timeOut: 10000,
            });

          },
          (errorResponse: any) => {
            counter++;
            if (counter === idStr.length) {
              this.concatUsernameInfo = '';
            }
            console.log('error getCustomerPendingRegistraions', errorResponse);
            this.toastr.error('Unable to delete the selected pending registrations.' + ' ' + errorResponse.error.message, 'Error', {
              timeOut: 10000,
            });
          }
        );
      }
    }

  }

  selectChangeAll(event: any): void {

    this.formListDS.filteredData.forEach(res => {
      if (event.checked === true) {
        res.isSelected = true;
        const id: string = res.id.toString().concat(';');
        this.concatUsernameInfo = this.concatUsernameInfo.concat(id);
      } else {
        res.isSelected = false;
        this.concatUsernameInfo = '';
      }
    });
    console.log(this.concatUsernameInfo);
  }
  selectChangeUnique(event: any, username: string, userId: string): void {
    this.formListDS.filteredData.forEach(res => {
      if (res.id === userId) {
        if (res.isSelected === false) {
          res.isSelected = true;
          if (event.checked === true) {
            const id: string = userId.toString().concat(';');
            this.concatUsernameInfo = this.concatUsernameInfo.concat(id);
          }
        } else {
          res.isSelected = false;
          if (this.concatUsernameInfo.includes(userId + ';')) {
            this.concatUsernameInfo = this.concatUsernameInfo.replace(userId + ';', '');
          }
        }
      }else{
        res.isSelected = false;
          if (this.concatUsernameInfo.includes(res.id + ';')) {
            this.concatUsernameInfo = this.concatUsernameInfo.replace(res.id + ';', '');
          }
      }
    });
    console.log(this.concatUsernameInfo);
  }

  renotifyCustomers(): void {
    const params = new URLSearchParams();
    params.set('concatenatedCustInfo', this.concatUsernameInfo);
    params.set('callbackUrl', window.location.origin);
    let idStr: string[];
    idStr = this.concatUsernameInfo.split(';');
    idStr = idStr.slice(0, idStr.length - 1);
    let counter = 0;
    for (const i in idStr) {

      if (idStr[i]) {

        this.iamUserTxnService.resendRegistrationEmail(idStr[i]).subscribe(
          (res: any) => {
            counter++;
            if (counter === idStr.length) {
              this.concatUsernameInfo = '';
              this.getCustomerPendingRegistraions();
            }
            this.toastr.success('Renotifed the selected users', 'Success', {
              timeOut: 10000,
            });

          },
          (errorResponse: any) => {
            counter++;
            if (counter === idStr.length) {
              this.concatUsernameInfo = '';
            }
            console.log('error while renotifying the users)', errorResponse);
            this.toastr.error('Unable to renotify the selected users' + ' ' + errorResponse.error.message, 'Error', {
              timeOut: 10000,
            });
          }
        );
      }
    }
  }

  applyFilter(filterValue:string): any {
      this.formListDS.filter = filterValue.trim().toLowerCase();
      this.formListDS.filteredData.forEach(
        (res: any, count) => {
          res.rowIndex = count + 1;
        });
    if (this.formListDS.paginator) {
      this.formListDS.paginator.firstPage();
    }
  }

  exportToCSV(): void {
    const tableDataCSV = [];

    this.formListDS.filteredData.forEach((element) => {
      const configHeader = this.formAttributeConfig;
      const tempObj: any = {};
      for (const attribute of configHeader) {


        let val = element[attribute.name];
        if (attribute.type === 'createDate') {
          val = this.datePipe.transform(element.status.date, 'mediumDate');
        }
        if (attribute.type === 'linkExpiredDate') {
          val = this.datePipe.transform(element.expiryDate, 'mediumDate');
        }
        else if (attribute.type === 'role') {
          val = '';
          let counter = 0;
          for (let role of element[attribute.name]) {
            val = val + role.roleCode;
            if (counter % 2 == 0 && element[attribute.name].length > 1) {
              val = val + ', ';
            }
            counter++;
          }
        }
        const key = attribute.label;
        tempObj[key] = val ? val : '';

      }
      tableDataCSV.push(tempObj);

    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: 'Pending_Registration_'+ this.datePipe.transform(new Date(), 'shortDate').split('/').join('.')

    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(tableDataCSV);
  }
}

