<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="showPageLoader" id="loaderdiv">
	<mat-spinner></mat-spinner>
</div>
<mat-card *ngIf="searchForm">
	<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">
		<div fxLayout="row wrap" fxLayoutAlign="start stretch">
			<div fxFlex="50" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="start center"
			[class]="this.valueIsDuplicate?'pb-4':''">
				<mat-label id="top-label" class="font-weight-bold float-left text-dark">Search by Customer
					Number, Account Number or Email Address:
				</mat-label>
			</div>
			<div fxFlex="50" fxFlex.lt-md="100" [formGroup]="searchForm" fxLayout="row"
				fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start stretch">
				<div fxFlex="80" fxLayout="column"  class="m-0" >
				<mat-form-field appearance="outline">
					<input type="search" matInput id="searchKey" (keydown.enter)="search()"
						placeholder="Search by Customer Number, Account Number or Email Address"
						formControlName="searchKey" required>
					<button matSuffix mat-icon-button aria-label="Clear"
						(click)="searchForm.controls.searchKey.setValue(null)">
						<mat-icon matSuffix>close</mat-icon>
					</button>

				</mat-form-field>

		        <!-- <mat-error *ngIf="this.valueIsDuplicate">
						Please enter a new value, search result is currently displayed for this search value
				</mat-error> -->
				</div>
				<!-- <div [class]="this.valueIsDuplicate?'pb-4':''"> -->
				<button type="button" class="btn btn-sm ux-icon-button" title="Search" (click)="search(false, true)"
					fxLayout="row" fxLayoutAlign="start center"
					[disabled]="searchForm.invalid || searchForm.errors?.fieldInavlid">
					<mat-icon data-toggle="tooltip" data-placement="bottom" title="Search" class="mr-2">
						search</mat-icon>
					<span>Search</span>
				</button>
				<!-- </div> -->
			</div>
			<!-- <div fxFlexOffset="50" fxFlex="50">
				<a href="{{myaccountUrl}}/myaccount.html" target="newmyaccount">New Customer(New window)</a>
			</div> -->
		</div>



		<div *ngIf="isError == true">
			<mat-error>
				<h2>Your search did not match any customer or user.</h2>
				<p> Suggestions:</p>
				<ul>
					<li>Make sure that all words are spelled correctly</li>
					<li>Try different keywords</li>
					<li>Try more general keywords</li>
					<li>Try fewer keywords</li>
				</ul>
			</mat-error>
		</div>

		<mat-accordion class="border border-light p-0 mt-2" *ngIf="showContent">
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header class="bg-red p-2" fxLayout="row" fxLayoutAlign="space-between center"
					fxLayoutGap.xs="10px">
					<mat-panel-title class="mb-0 text-white font-weight-bold">Customer Details</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between start" class="mb-0 m-2"
					fxLayoutAlign.xs="start start" *ngIf="customerInfo" fxLayoutGap.xs="5px">
					<div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start space-between"
						fxLayoutAlign="space-between center">
						<div fxFlex="30" class="p-2">Customer ID:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span>{{customerInfo.id || '-'}}</span>
						</div>
					</div>
					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2" *ngIf="customerInfo.customerType =='P'">Full Name:</div>
						<div fxFlex="30" class="p-2" *ngIf="customerInfo.customerType =='O'">Customer Name:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span *ngIf="customerInfo.customerType =='P'">{{customerInfo.fullName}}</span>
							<span *ngIf="customerInfo.customerType =='O'">{{customerInfo.organization}}</span>
						</div>
					</div>

					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2 white-sp-responsive">Email(s):</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<div *ngIf="customerInfo.emails && customerInfo.emails.length>0" class="">
								<span *ngIf="customerInfo.emails.length==1">{{customerInfo.emails[0].emailAddress}}</span>
								<ul *ngIf="customerInfo.emails.length>1" class="p-0">
									<li *ngFor="let email of customerInfo.emails;let i=index" class="font-weight-bold">

										<span *ngIf="customerInfo.emails.length>1">{{email.emailAddress}}
											{{email.standard ? ' (Standard)' : ''}}</span></li>
								</ul>
							</div>
							<span *ngIf="!customerInfo.emails || customerInfo.emails.length == 0"> - </span>
						</div>
					</div>
					<div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start space-between"
						fxLayoutAlign="space-between start">
						<div fxFlex="30" class="p-2">Mailing Address:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<div *ngIf="customerInfo.mailingAddresses && customerInfo.mailingAddresses.length > 0" class="mb-0">
								<span *ngIf="customerInfo.mailingAddresses.length==1">
									{{customerInfo.mailingAddresses[0].streetSupplemental1 ? spaceReplaced(customerInfo.mailingAddresses[0].streetSupplemental1).trim()+', ':''}}
									{{customerInfo.mailingAddresses[0].streetSupplemental2 ? spaceReplaced(customerInfo.mailingAddresses[0].streetSupplemental2).trim()+', ':''}}
									{{spaceReplaced(customerInfo.mailingAddresses[0].shortAddress)}}, {{spaceReplaced(customerInfo.mailingAddresses[0].postalCode)}}</span>
								<ul *ngIf="customerInfo.mailingAddresses.length>1" style="margin-left:-40px">
									<li *ngFor="let mailingAddress of customerInfo.mailingAddresses;let i=index" class="font-weight-bold">
										{{spaceReplaced(mailingAddress.fullAddress)}}


										<span>{{(mailingAddress.fullAddress && mailingAddress.standard) ? ' (Standard)' : ''}}</span>
									</li>
								</ul>
							</div>
							<span *ngIf="!customerInfo.mailingAddresses || customerInfo.mailingAddresses.length == 0">-</span>
						</div>
					</div>

					<div fxFlex="50" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2 white-sp-responsive">Phone Number(s):</div>
						<div fxFlex="70" class="font-weight-bold p-2">
							<ul *ngIf="customerInfo && customerInfo.phoneNumbers" class="p-0">
								<div *ngFor="let category of phoneCategories" fxLayout="column">
									<div *ngIf="hasPhoneCategory(category.category)" fxLayout="row">
										<li *ngIf="getPhoneNumbers(category.category).length > 0">{{category.label + ':'}}&nbsp; </li>
										<div>
											<ng-container *ngFor="let phnNumber of (getPhoneNumbers(category.category) | subListFilter: 3);let i=index">
												<span><span *ngIf="i>0">, </span>
													{{phnNumber.phoneNumber ? formatPhnNumber(phnNumber.phoneNumber) : '-' }}</span>
											</ng-container>
											<span *ngIf="getPhoneNumbers(category.category).length > 3" class="sublist"> (3 of
												{{getPhoneNumbers(category.category).length}})</span>
										</div>
									</div>
								</div>
							</ul>
							<div *ngIf="!customerInfo.phoneNumbers || !customerInfo">
												<span>{{'-'}}</span>
							</div>

						</div>
						<!-- <div fxFlex="70" class="p-2 font-weight-bold text-break" * ngIf="false">
							<div *ngIf="customerInfo?.businessNumbers?.length==1 &&
							customerInfo?.residentialNumbers?.length<1 && customerInfo?.cellNumbers?.length<1 &&
							customerInfo?.notificationNumbers?.length<1">
					 			<span>Business: </span>{{customerInfo.businessNumbers[0].phoneNumber || '-'}}
								{{customerInfo.businessNumbers[0].standard ? ' (Standard)' : ''}}
							</div>
							<div *ngIf="customerInfo?.businessNumbers?.length<1 &&
							customerInfo?.residentialNumbers?.length==1  && customerInfo?.cellNumbers?.length<1 &&
							customerInfo?.notificationNumbers?.length<1">
								<span>Residential: </span>{{customerInfo.residentialNumbers[0].phoneNumber || '-'}}
								{{customerInfo.residentialNumbers[0].standard ? ' (Standard)' : ''}}
							</div>
							<div *ngIf="customerInfo?.businessNumbers?.length<1 &&
							customerInfo?.residentialNumbers?.length<1  && customerInfo?.cellNumbers?.length==1 &&
							customerInfo?.notificationNumbers?.length<1">
								<span>cell: </span>{{customerInfo.cellNumbers[0].phoneNumber || '-'}}
								{{customerInfo.cellNumbers[0].standard ? ' (Standard)' : ''}}
							</div>
							<div *ngIf="customerInfo?.businessNumbers?.length<1 &&
							customerInfo?.residentialNumbers?.length<1  && customerInfo?.cellNumbers?.length<1 &&
							customerInfo?.notificationNumbers?.length==1">
								<span>Notification: </span>{{customerInfo.notificationNumbers[0].phoneNumber || '-'}}
								{{customerInfo.notificationNumbers[0].standard ? ' (Standard)' : ''}}
							</div>
							<ul *ngIf="customerInfo.phoneNumbers
							&& (customerInfo?.businessNumbers?.length>1 ||
							customerInfo?.residentialNumbers?.length>1  || customerInfo?.cellNumbers?.length>1 ||
							customerInfo?.notificationNumbers?.length>1) " class="mb-0" style="margin-left:-40px">

								<li *ngFor="let phNum of customerInfo.businessNumbers" class="font-weight-bold">
									<span>Business: </span>{{phNum.phoneNumber || '-'}}
									{{phNum.standard ? ' (Standard)' : ''}}</li>
								<li *ngFor="let phNum of customerInfo.residentialNumbers" class="font-weight-bold">
									<span>Residential: </span>{{phNum.phoneNumber || '-'}}
									{{phNum.standard ? ' (Standard)' : ''}}</li>
								<li *ngFor="let phNum of customerInfo.cellNumbers" class="font-weight-bold">
									<span>Cell: </span>{{phNum.phoneNumber || '-'}}
									{{phNum.standard ? ' (Standard)' : ''}}</li>
								<li *ngFor="let phNum of customerInfo.notificationNumbers" class="font-weight-bold">
									<span>Notification: </span>{{phNum.phoneNumber || '-'}}
									{{phNum.standard ? ' (Standard)' : ''}}</li>
							</ul>
							<span *ngIf="!customerInfo.phoneNumbers">-</span>
						</div> -->
					</div>
					<div *ngIf="customerList && customerList.length > 1" fxFlex="100" fxLayout="row"
						fxLayout.xs="column" class="disclaimer text-danger">
						<mat-icon class="align mat-tooltip-panel" matTooltipPosition="below">warning</mat-icon>
						<span> Note - Multiple customer ID&nbsp;</span>
						 (
						<span
							*ngFor="let info of customerList;let i=index">{{(i < (customerList.length-1) ? info.id + ',' : info.id) || '-'}}
						</span>
						)
						<span>&nbsp;mapped to this email. Please search with these Customer IDs to get details of each of them.</span>
					</div>

				</mat-card-content>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0"
					*ngIf="!customerInfo">
					<div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="p-2 font-weight-bold text-break">
						<span>Not Linked with any Customer</span>
					</div>
				</mat-card-content>
			</mat-expansion-panel>
		</mat-accordion>

		<mat-accordion class="border border-light p-0 mt-2" *ngIf="showContent && customerInfo && customerInfo.childCustomers && customerInfo.childCustomers.length>0">
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header class="bg-red p-2" fxLayout="row" fxLayoutAlign="space-between center"
					fxLayoutGap.xs="10px">
					<mat-panel-title class="mb-0 text-white font-weight-bold">Child Customer Details</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="toggle-section" fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="end end">
					<mat-icon matTooltip="Table view" matTooltipPosition="left" *ngIf="state ==='list'" (click)="onToggle('list')">view_stream</mat-icon>
					<mat-icon matTooltip="List view" matTooltipPosition="left" *ngIf="state ==='table'" (click)="onToggle('table')">view_list</mat-icon>
				</div>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between start" class="mb-0 m-2"
					fxLayoutAlign.xs="start start" *ngIf="customerInfo && state==='list'" fxLayoutGap.xs="5px" >
					<ng-container *ngFor="let childCustomer of customerInfo.childCustomers;let i=index">

						<div fxFlex="50" fxLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start space-between"
							fxLayoutAlign="space-between center">
							<div fxFlex="30" class="p-2">Customer ID:</div>
							<div fxFlex="70" class="p-2 font-weight-bold text-break">
								<span>{{childCustomer.id || '-'}}</span>
							</div>
						</div>
						<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
							fxLayoutAlign.xs="start space-between">
							<div fxFlex="30" class="p-2" *ngIf="childCustomer.customerType =='P'">Full Name:</div>
							<div fxFlex="30" class="p-2" *ngIf="childCustomer.customerType =='O'">Customer Name:</div>
							<div fxFlex="70" class="p-2 font-weight-bold text-break">
								<span *ngIf="childCustomer.customerType =='P'">{{childCustomer.fullName ? childCustomer.fullName : childCustomer.firstName + ' ' + childCustomer.lastName}}</span>
								<span *ngIf="childCustomer.customerType =='O'">{{childCustomer.organization}}</span>
							</div>
						</div>
						<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
							fxLayoutAlign.xs="start space-between">
							<div fxFlex="30" class="p-2 white-sp-responsive">Email(s):</div>
							<div fxFlex="70" class="p-2 font-weight-bold text-break">
								<div *ngIf="childCustomer.emails && childCustomer.emails.length>0" class="">
									<span *ngIf="childCustomer.emails.length==1">{{childCustomer.emails[0].emailAddress}}</span>
									<ul *ngIf="childCustomer.emails.length>1" class="p-0">
										<li *ngFor="let email of childCustomer.emails;let i=index" class="font-weight-bold">

											<span *ngIf="childCustomer.emails.length>1">{{email.emailAddress}}
												{{email.standard ? ' (Standard)' : ''}}</span>
										</li>
									</ul>
								</div>
								<span *ngIf="!customerInfo.emails || customerInfo.emails.length == 0"> - </span>
							</div>
						</div>
						<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
							fxLayoutAlign.xs="start space-between">
							<div fxFlex="100" class="p-2 font-weight-bold text-break">
								<button mat-raised-button class="btn btn-sm ux-icon-button" (click)="unlinkCustomer(childCustomer, customerInfo)"
									data-toggle="tooltip" title="Link Customer">Unlink</button>
							</div>
						</div>
						<div fxFlex="100" fxLayout="column" class="dashed-line-box" *ngIf="i < customerInfo.childCustomers.length - 1">
						</div>
					</ng-container>
				</mat-card-content>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between start" class="mb-0 m-2"
				fxLayoutAlign.xs="start start" *ngIf="customerInfo && state==='table'" fxLayoutGap.xs="5px" >
				<mat-table *ngIf="customerInfo && customerInfo.childCustomers" [dataSource]="customerInfo.childCustomers" class="mat-elevation-z8">
					<ng-container matColumnDef="customerId">
					  <mat-header-cell *matHeaderCellDef> Customer ID. </mat-header-cell>
					  <mat-cell *matCellDef="let element"> {{element.id || '-'}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span *ngIf="element.customerType =='P'">{{element.fullName ? element.fullName : element.firstName + ' ' + element.lastName || '-'}}</span>
						    <span *ngIf="element.customerType =='O'">{{element.organization || '-'}}</span>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="email">
						<mat-header-cell *matHeaderCellDef> Email(s) </mat-header-cell>
						<mat-cell *matCellDef="let element">
							<span *ngIf="element.emails==null || element.emails.length==0">-</span>
							<span *ngIf="element.emails && element.emails.length==1">{{element.emails[0].emailAddress}}</span>
							<ul *ngIf="element.emails && element.emails.length>1" class="p-0">
								<li *ngFor="let email of element.emails;let i=index">
									<span *ngIf="element.emails.length>1">{{email.emailAddress}}
										{{email.standard ? ' (Standard)' : ''}}</span>
								</li>
							</ul>
						</mat-cell>
					</ng-container>
					<ng-container matColumnDef="unlink">
						<mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</mat-header-cell>
						<mat-cell *matCellDef="let element">
							<button mat-raised-button class="btn btn-sm ux-icon-button"(click)="unlinkCustomer(element, customerInfo)"
							data-toggle="tooltip" title="Link Customer">Unlink</button>
						</mat-cell>
					  </ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
				</mat-table>
			</mat-card-content>
			</mat-expansion-panel>
		</mat-accordion>

		<mat-accordion class="border border-light p-0 mt-2" *ngIf="showContent">
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header class="bg-red p-2" fxLayout="row" fxLayoutAlign="space-between center">
					<mat-panel-title class="mb-0 text-white font-weight-bold">Portal Access</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0"
					*ngIf="userAccount !== null && !notYetRegistered" fxLayoutGap.xs="5px">
					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2">Login Email:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span *ngIf="userAccount && !notYetRegistered">{{userAccount.username || '-'}}</span>
							<span *ngIf="userAccount && !notYetRegistered">{{userAccount?.customerId ? ' ('+userAccount.customerId+')' : ''}}</span>
							<span *ngIf="(!userAccount && !customerInfo) || notYetRegistered">Not Registered
								Yet</span>
						</div>
					</div>
					<div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2">Portals with Roles:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break" fxLayoutAlign="start center">
							<div *ngIf="portals && portals.length>0" class="mb-0">
								<span *ngIf="portals.length==1">{{portals}} ({{portalMap[portals]}})</span>
								<ul *ngIf="portals.length>1">
									<li *ngFor="let portal of portals" class="font-weight-bold"> {{portal}}

										({{portalMap[portal]}})</li>
								</ul>
							</div>
							<div class="mb-0" *ngIf="!portals || portals.length==0">
								<span class="font-weight-bold text-break">Shell Account</span>
							</div>
						</div>
					</div>
				</mat-card-content>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0"
					*ngIf="userAccount !==null && !notYetRegistered" fxLayoutGap.xs="5px">
					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2">Status:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span [class]="userAccount.status.value">
								{{(('user.status.' + userAccount.status.value) |  labelTranslate ) || '-'}} </span>
								<span>
									{{userAccount.status?.remark?.trim() ? '('+userAccount.status.remark+')'  : ''}}
								</span>
						</div>
					</div>
					<div *ngIf="userAccount.status.value && userAccount.status.value !== 'Active'" fxFlex="50" fxLayout="row"
						fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign="start space-between">
						<div fxFlex="30" class="p-2">{{(('user.statusdate.' + userAccount.status.value) |  labelTranslate ) + ':'|| '-'}}</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span>{{(userAccount.status?.date  | date : 'short') || '-'}}</span>
						</div>
					</div>
					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2">Web Account Status:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<mat-icon class="align mat-tooltip-panel"
								matTooltip="{{userAccount.locked ? 'Locked' : 'Unlocked'}}" matTooltipPosition="below">
								{{userAccount.locked ? 'lock' : 'lock_open'}}</mat-icon>
						</div>
					</div>
					<div *ngIf="userAccount.createdDate" fxFlex="50" fxLayout="row"
						fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign="start space-between">
						<div fxFlex="30" class="p-2">Registration Date:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span>{{(userAccount.createdDate  | date : 'short') || '-'}}</span>
						</div>
					</div>
					<div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center"
						*ngIf="!userAccount.registrationPending" fxLayout.xs="column"
						fxLayoutAlign.xs="start space-between">
						<div fxFlex="30" class="p-2">Last Login:</div>
						<div fxFlex="70" class="p-2 font-weight-bold text-break">
							<span class="align mat-tooltip-panel"
								matTooltip="Note: last login date means the last time the customer successfully input their email and password in the login screen to get to the next step, not necessarily when they successfully accessed the portal."
								matTooltipPosition="above">
								{{(userAccount.loginTryDate | date :'short') || '-'}}
							</span>
						</div>
					</div>
					<!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center"
						*ngIf="!userAccount.registrationPending">
						<div fxFlex="30" class="p-2">Last Login IP:</div>
						<div fxFlex="70" class="p-2 font-weight-bold">
							<span>{{userAccount?.lastLoginIP || '-'}}</span>
						</div>
					</div> -->
					<!-- <div *ngIf="userList && userList.length > 1" fxFlex="100" fxLayout="row"
						fxLayout.xs="column" class="disclaimer text-danger">
						<mat-icon class="align mat-tooltip-panel" matTooltipPosition="below">warning</mat-icon>
						<span> Note - Mulitple emails&nbsp;</span>
						(
						<span
							*ngFor="let info of userList;let i=index">{{(i < (userList.length-1) ? info.username + ',&nbsp;' : info.username) || '-'}}
						</span>
						)
						<span>&nbsp;mapped to this Customer ID.</span>

					</div> -->
				</mat-card-content>
				<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0"
					*ngIf="userAccount == null || notYetRegistered">
					<div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="p-2 font-weight-bold text-break">
						<span>Not Registered Yet</span>
					</div>
				</mat-card-content>
			</mat-expansion-panel>
		</mat-accordion>

		<mat-card-actions *ngIf="showContent" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px"
			class="bg-light" fxLayoutGap.xs="10px" fxLayout.xs="column"  fxLayoutGap.lt-md="10px" fxLayout.lt-md="column">

			<button mat-raised-button class="btn btn-sm ux-icon-button" (click)="linkCustomer(customerInfo)"
			    *ngIf="customerInfo && linkCustomerEnabled"
				[disabled]="disableActionForMulitpleInstances" data-toggle="tooltip" title="Link Customer">Link Customer</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button"
				*ngIf="userAccount && !notYetRegistered && !userAccount.suspended" [disabled]="!userAccount.enabled || disableActionForMulitpleInstances"
				(click)="showBlockAccount(userAccount)" data-toggle="tooltip" title="Block Online Account">Block Online
				Account</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button"
				*ngIf="userAccount && !notYetRegistered && userAccount.suspended"
				(click)="showUnblockAccount(userAccount)" data-toggle="tooltip" title="Block Online Account"
				[disabled]="disableActionForMulitpleInstances">Unblock
				Online Account</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button" *ngIf="userAccount && !notYetRegistered"
				[disabled]="(userAccount.pending || userAccount.status.value === 'Deactivate') || disableActionForMulitpleInstances"
				(click)="showChangeLogin(userAccount)" data-toggle="tooltip" title="Change Login Email">Change Login
				Email</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button" *ngIf="userAccount && !notYetRegistered && !isCSRrole"
				[disabled]="userAccount.deactivated || disableActionForMulitpleInstances" (click)="showCloseOnlineAccount(userAccount)" data-toggle="tooltip"
				title="Close Online Account">Close Online Account</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button"
				*ngIf="userAccount && userAccount.registrationPending" data-toggle="tooltip"
				[disabled]="disableActionForMulitpleInstances"
				title="Send Registration Email" (click)="sendRegistrationEmail(userAccount)">Send Registration
				Email</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button"
				*ngIf="isSendInvitationEnabled && (userAccount==null || notYetRegistered) && customerInfo?.emails?.length>0" data-toggle="tooltip"
				[disabled]="disableActionForMulitpleInstances"
				title="Send Registration Email" (click)="sendInvitationEmail()">Send Invitation Email</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button" *ngIf="!isShellAccount && customerInfo && customerInfo.id && isCustomerViewEnabled"
			[disabled]="disableActionForMulitpleInstances"
				(click)="openCustomerView()" data-toggle="tooltip" title="Customer View">Customer View</button>

			<button mat-raised-button class="btn btn-sm ux-icon-button" *ngIf="!isShellAccount && customerInfo && isGreenButtonEnabled"
			[disabled]="disableActionForMulitpleInstances"
				(click)="openGreenButtonView()" data-toggle="tooltip" title="Green Button View">Green Button View</button>
		</mat-card-actions>
	</mat-card-content>
</mat-card>