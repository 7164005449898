import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AbstractComponent, AppConfg, APP_CONIG, BrandingConfig, BRANDING_CONFIG } from '@londonhydro/ux-lib';
import { AccountDataProvider } from '../../backend/csr/dataservice/account.dataprovider';
import { BlockAccountFormComponent } from '../block-account-form/block-account-form.component';
import { ChangeLoginEmailFormComponent } from '../change-login-email-form/change-login-email-form.component';
import { CloseOnlineAccountFormComponent } from '../close-online-account-form/close-online-account-form.component';
import { SendRegistraionEmailFormComponent } from '../send-registraion-email-form/send-registraion-email-form.component';
import { UnblockAccountFormComponent } from '../unblock-account-form/unblock-account-form.component';
import { UserSearchFormComponent } from '../user-search-form/user-search-form.component';
import * as _ from 'underscore';

@Component({
  selector: 'app-user-detail-view',
  templateUrl: './user-detail-view.component.html',
  styleUrls: ['./user-detail-view.component.scss']
})
export class UserDetailViewComponent extends AbstractComponent implements OnInit, OnDestroy {
  userData: any;
  disableBlock: boolean;
  brandingName: any;
  isShellAccount: boolean;
  isCSRrole: boolean;
  notYetRegistered: boolean;
  greenButtonEnabled = false;

  constructor(injector: Injector, route: ActivatedRoute,
    accountProvider: AccountDataProvider,
    public dialogRef: MatDialogRef<UserSearchFormComponent>, @Inject(MAT_DIALOG_DATA) public parentData: any,
    private matDialog: MatDialog, @Inject(APP_CONIG) public appConfig: AppConfg,
    @Inject(BRANDING_CONFIG) public brandingConfig: BrandingConfig) {
    super(injector);
    this.userData = parentData.data;
    this.brandingName=parentData.brandingName;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isShellAccount = (typeof this.userData.customerId === 'undefined' || this.userData.customerId === null) ? true : false;
    this.isCSRrole = _.filter(this.userData.authorities, (res: any) => {
        return res.roleCode.toLowerCase().includes('csr') }).length > 0;
    this.notYetRegistered = (this.userData.username && (this.userData.username.indexOf('(Not Registered Yet)') >= 0
        || this.userData.username.indexOf('NotRegister') >= 0)) ? true : false;

    this.getAppConfig();
  }

  userSessionLoaded(): void {
    console.log('Element Data', this.userData);

  }

  closeModal(str?: string): void {
    this.dialogRef.close(str);
  }
  ngOnDestroy(): void {
    console.log('On child component class::::ngOnDestroy');
    super.ngOnDestroy();
  }

  openCustomerView(): void {
    const customerId = this.userData.customerId;
    let username = '';
    let userId = '';
    if (this.userData){
      username = this.userData.username;
      userId = this.userData.id;
    }
    const v = (new Date()).getTime();
    const myAccountViewUrl = this.appConfig['myAccountUrl'] + `?v=${v}&customerId=${customerId}` + (this.userData ? (`&username=${encodeURIComponent(username)}&userId=${userId}`) : '');
    console.log('myAccountViewUrl:::::', myAccountViewUrl);
    window.open(myAccountViewUrl, '_view' + customerId);
  }

  getAppConfig(): boolean{
    if (this.appConfig && this.appConfig.greenButtonUrl) {
      this.greenButtonEnabled = true;
    }
    return this.greenButtonEnabled;
  }

  openGreenButtonView(): void {
    const customerId = this.userData?.customerId;
    const greenButtonViewUrl = this.appConfig.greenButtonUrl + `?customerId=${customerId}`;
    window.open(greenButtonViewUrl, '_view' + customerId);
  }

  showChangeLoginEmailViewer(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'change-email-modal-component' + this.userData.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.userData;
    const emailChangeViewer = this.matDialog.open(ChangeLoginEmailFormComponent, dialogConfig);
    emailChangeViewer.afterClosed().subscribe((response: any) => {
      if (response === 'success') {
        this.closeModal('success');
      }
    });
  }

  showBlockAccountViewer(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'block-modal-component' + this.userData.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {data:this.userData,siteName:this.brandingName};
    const blockAccountViewer = this.matDialog.open(BlockAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      if (res === 'success') {
        this.closeModal('success');
      }
    });
  }

  showCloseOnlineAccountViewer(data: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'close-account-modal-component' + this.userData.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.userData;
    const closeAccountViewer = this.matDialog.open(CloseOnlineAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      if (res === 'success') {
        this.closeModal('success');
      }
    });
  }

 sendRegistrationEmailViewer(): void{
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = false;
     dialogConfig.id = 'send-email-modal-component' + this.userData.id;
     dialogConfig.width = '500px';
     dialogConfig.height = 'auto';
     dialogConfig.data = this.userData;
     const showSendRegistrationEmail = this.matDialog.open(SendRegistraionEmailFormComponent, dialogConfig);
  }


  showUnblockAccountViewer(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'unblock-account-modal-component' + this.userData.id;
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {data:this.userData,siteName:this.brandingName};
    const unblockAccountViewer = this.matDialog.open(UnblockAccountFormComponent, dialogConfig).afterClosed().subscribe((res) => {
      if (res === 'success') {
        this.closeModal('success');
      }
    });
  }
}


