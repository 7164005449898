<div>
	<section id="modal-body">
		<mat-card class="ux-card border border-light p-0">
			<mat-card-header class=" p-2" fxLayout="row" fxLayoutAlign="space-between center">
				<mat-card-title class="mat-card-title">User Details Viewer</mat-card-title>
				<button (click)="closeModal()" class="btn btn-link float-right" type="button" id="dismiss"
					aria-label="Close">
					<mat-icon class="material-symbols-outlined">close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content class="content-body p-2" fxLayout="column" fxLayoutAlign="start stretch">

				<mat-accordion class="border border-light p-0">
					<mat-expansion-panel [expanded]="true">
						<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
							fxLayoutAlign="space-between center">
							<mat-panel-title class="mb-0 text-white font-weight-bold">User Details</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
							<div *ngIf="userData" fxFlex="100" fxLayout="row" fxLayout.xs="column"
								fxLayoutAlign="space-between center" fxLayoutAlign.xs="stretch">
								<div fxFlex="50" fxFlex.xs="100" class="p-2">
									<mat-label fxFlex="50" fxFlex.xs="50"><span>Login Email: </span></mat-label>
									<mat-label fxFlex="50" fxFlex.xs="50" class="font-weight-bold text-break">
										{{userData.username}}</mat-label>
								</div>

								<div fxFlex="50" fxFlex.xs="100" class="p-2">
									<span>
										<mat-label fxFlex="50" fxFlex.xs="50"><span>Customer
												ID: </span></mat-label><span fxFlex.xs="50" fxFlex="50"
											class="font-weight-bold text-break">{{userData.customerId}}</span>
									</span>
								</div>
							</div>
							<div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center"
								fxLayoutAlign.xs="space-between">
								<div fxFlex="50" fxFlex.xs="100" class="p-2">
									<span *ngIf="userData">
										<mat-label fxFlex="50" fxFlex.xs="50"><span>Full Name: </span></mat-label>
										<span fxFlex="50" fxFlex.xs="50" class="font-weight-bold text-break">
											{{userData.fullName || '-'}}</span>
									</span>
								</div>
							</div>
						</mat-card-content>
					</mat-expansion-panel>
				</mat-accordion>

				<div fxLayout="column">

					<mat-accordion class="border border-light p-0 mt-2">
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between center">
								<mat-panel-title class="mb-0 text-white font-weight-bold">My Account Activity
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
								<div fxFlex="100" fxLayout="row" fxLayout.xs="column"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between">
									<div fxFlex="100" class="p-2" *ngIf="userData?.registrationPending === true">
										<h4>Not Registered Yet</h4>
									</div>
									<div fxFlex="100" fxLayout="row" fxLayout.xs="column"
										*ngIf="userData?.registrationPending === false">
										<div fxFlex="50" fxFlex.xs="100" class="p-2">
											<mat-label fxFlex="50" fxFlex.xs="50"><span>User
													Status: </span></mat-label><span fxFlex="50" fxFlex.xs="50"
												[class]="userData.status.value"
												class="font-weight-bold">{{userData.status.value}}</span>
										</div>
										<div fxFlex="50" fxFlex="100" class="p-2"
											*ngIf="userData?.createdDate">
											<mat-label fxFlex="50" fxFlex.xs="70"><span>Registration Date: </span></mat-label>
											<span fxFlex="50" fxFlex.xs="40"
												class="font-weight-bold text-break">{{(userData.createdDate  | date :'mediumDate') || '-'}}</span>
										</div>
									</div>
								</div>
								<div fxFlex="100" fxLayout="row" fxLayout.xs="column"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between">
									<div fxFlex="100" class="p-2" *ngIf="userData?.registrationPending === false">
										<div>
											<mat-label fxFlex="50" fxFlex.xs="70"><span>Web Account
													Locked: </span></mat-label><span fxFlex="50" fxFlex.xs="30"
												class="font-weight-bold text-break">{{userData.locked === false ? 'No' : 'Yes'}}</span>
										</div>
										<!-- <div fxFlex="50">
						<span class="pr-3">Paperless
							Billing:</span><span><strong>{{userData.paperlessBilling === false ? 'No' : 'Yes'}}</strong></span>
					</div> -->
									</div>
									<div fxFlex="100" class="p-2" *ngIf="userData?.registrationPending === false">
										<div>
											<mat-label fxFlex="50" fxFlex.xs="50"><span>Last Login
													Date: </span></mat-label><span fxFlex="50" fxFlex.xs="50"
												class="font-weight-bold text-break">{{userData.lastLoginDate === null ? 'Not found'  : userData.lastLoginDate | date :'mediumDate' }}</span>
										</div>
										<!-- <div fxFlex="50">
						<span>Last Login
							IP:</span><span><strong>{{userData.lastLoginIP}}</strong></span>
					</div> -->
									</div>
								</div>
								<div fxFlex="100" fxLayout="row" fxLayout.xs="column"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between"
									*ngIf="userData?.status?.value && userData?.status?.value !== 'Active'">
									<div fxFlex="50" class="p-2">
										<div>
											<mat-label fxFlex="50" fxFlex.xs="60">
												{{('user.statusdate.' + userData.status.value |  labelTranslate )|| '-'}}:
											</mat-label>
											<span fxFlex="50" fxFlex.xs="40"
												class="font-weight-bold text-break">{{(userData.status?.date  | date :'mediumDate') || '-'}}</span>
										</div>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<div fxLayout="column">
					<mat-accordion class="border border-light p-0 mt-2">
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header class="bg-red p-2" fxLayout="row"
								fxLayoutAlign="space-between center">
								<mat-panel-title class="mb-0 text-white font-weight-bold">Portal Access
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-card-content fxLayout="row wrap" fxLayoutAlign="space-between center" class="mb-0">
								<div fxFlex="100" fxLayout.xs="column" fxLayout="row"
									fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between">
									<div fxLayout="row" fxLayout.xs="column" class="p-2" fxFlex="100">
										<!--  *ngIf="userData.authorities.length" -->
										<span fxFlex="50" fxFlex.xs="100">Roles Associated with User:&nbsp;</span>
										<span fxFlex="50" fxFlex.xs="100">
											<span *ngFor="let role of userData.authorities;index as i">
												<span class="font-weight-bold text-break "> <span *ngIf="i>0">,</span>
													{{role.authority}}</span>
											</span>
										</span>
									</div>
									<div fxFlex="50" fxFlex.xs="100" class="p-2">
										<mat-label fxFlex="50" class="p-2"><span>Remarks</span></mat-label>
										<mat-label fxFlex="50" class="p-2 font-weight-bold text-break">
											<span>
												{{userData.status.remark ? userData.status.remark  : '-'}}
											</span>
										</mat-label>
									</div>
								</div>
							</mat-card-content>
						</mat-expansion-panel>
					</mat-accordion>
				</div>

				<mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="end center"
					fxLayoutGap="15px" class="bg-light px-2 fixed-boattom sticky-footer">
					<button data-toggle="tooltip" title="Send Registration Email" type="button"
						class="btn btn-sm ux-icon-button" (click)="sendRegistrationEmailViewer()"
						*ngIf="userData.registrationPending">Send Registration Link</button>
					<button data-toggle="tooltip" title="Block Account" type="button" class="btn btn-sm ux-icon-button"
						(click)="showBlockAccountViewer()" *ngIf="!notYetRegistered && !userData.suspended"
						[disabled]="!userData.enabled">Block Account</button>
					<button data-toggle="tooltip" title="Unblock Account" type="button"
						class="btn btn-sm ux-icon-button" (click)="showUnblockAccountViewer()"
						*ngIf="!notYetRegistered && userData.suspended">Unblock Account</button>
					<button data-toggle="tooltip" title="Close Account" type="button" class="btn btn-sm ux-icon-button"
						(click)="showCloseOnlineAccountViewer()"
						*ngIf="!userData.deactivated && !notYetRegistered && !isCSRrole">{{isShellAccount ? 'Delete Shell Account' : 'Close Online Account'}}</button>
					<button data-toggle="tooltip" title="Change Login Email" type="button"
						class="btn btn-sm ux-icon-button" (click)="showChangeLoginEmailViewer()"
						*ngIf="!notYetRegistered"
						[disabled]="userData.pending || userData.status.value === 'Deactivate'">Change Login
						Email</button>
					<button data-toggle="tooltip" title="View Customer" type="button" class="btn btn-sm ux-icon-button"
						(click)="openCustomerView()" *ngIf="!isShellAccount && (!brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] || brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] === false)">Customer View</button>
						<button data-toggle="tooltip" title="Green Button View" type="button" class="btn btn-sm ux-icon-button"
						(click)="openGreenButtonView()" *ngIf="!isShellAccount && greenButtonEnabled && (!brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] || brandingConfig[appConfig['brandName']]['hideCustomerViewForUserAdmin'] === false)">Green Button View</button>
				</mat-card-actions>

				<ng-template #noDataBlock>
					<div mat-dialog-content><strong>No Data Found.</strong></div>
				</ng-template>
			</mat-card-content>
		</mat-card>
	</section>
</div>

